import { useFormik } from 'formik';
import styles from './styles.module.scss';
import InputMask from 'react-input-mask';
import { Button } from '../Button/Button';
import { PlusIcon } from '../Icons/PlusIcon';
import { PlayIcon } from '../Icons/PlayIcon';
import { useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppContext } from '../../App';
import { CloseIcon } from '../Icons/CloseIcon';
import Dropdown from 'react-dropdown';
import { ArrowIcon } from '../Icons/ArrowIcon';
import { Modal } from '../Modal/Modal';
import { SuccessIcon } from '../Icons/SuccessIcon';
import * as Yup from 'yup';
import { AudioPlayer } from '../AudioPlayer/AudioPlayer';

const pathsMap = {
  record: require('../../assets/audio/record.mp3'),
}

const FormSchema = Yup.object().shape({
  fullName: Yup.string()
    .required(),
  email: Yup.string().email().required(),
  phone: Yup.string()
    .required(),
  desc: Yup.string().optional(),
  howToUse: Yup.string().required(),
  clients: Yup.string().optional(),
  region: Yup.string().required(),
  site: Yup.string().required(),
});

export const ContactForm = observer(() => {
  const { store } = useContext(AppContext)
  const [success, setSuccess] = useState(false)
  const { errors, touched, ...formik } = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      site: '',
      region: '',
      desc: '',
      howToUse: '',
      clients: '',
    },
    validationSchema: FormSchema,
    onSubmit: (values: any) => {
      fetch('https://sasha.sale:3001/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setSuccess(true);
          } else {
            // Handle error
            console.error('Error submitting form:', data.message);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  });

  const descOptions = [
    'Большой бизнес',
    'Малый и средний бизнес',
    'CRM & SaaS платформа, ищущая интеграцию с AI',
    'Инвестор',
    'Сотрудник',
  ].map(value => ({ value, label: value }));

  const clientsOptions = [
    'До 10.000 клиентов',
    'От 10.000 до 49.999 клиентов',
    'От 50.000 до 250.000 клиентов',
    'Более 250.000 клиентов',
  ].map(value => ({ value, label: value }));

  if (!store.contactFormIsOpen) {
    return null
  }

  const Form = (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        Заполните анкету
      </div>
      <div className={styles.content}>
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <input
            type="text"
            name="fullName"
            placeholder="Имя*"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} // Add this line
            value={formik.values.fullName}
            className={`${styles.formControl} ${errors.fullName && touched.fullName ? styles.hasError : ''}`}
          />
          <input
            type="email"
            name="email"
            placeholder="Почта*"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} // Add this line
            value={formik.values.email}
            className={`${styles.formControl} ${errors.email && touched.email ? styles.hasError : ''}`}
          />
          <InputMask
            mask="+7 (999) 999-99-99"
            name="phone"
            placeholder="+7 (999) 999-99-99*"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} // Add this line
            value={formik.values.phone}
            className={`${styles.formControl} ${styles.formControlPhone} ${errors.phone && touched.phone ? styles.hasError : ''}`}
          />
          <input
            type="text"
            name="site"
            placeholder="Сайт компании"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} // Add this line
            value={formik.values.site}
            className={`${styles.formControl} ${errors.site && touched.site ? styles.hasError : ''}`}
          />
          <textarea
            name="region"
            placeholder="В каком регионе и на каком языке работаете?"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} // Add this line
            value={formik.values.region}
            className={`${styles.formControl} ${errors.region && touched.region ? styles.hasError : ''}`}
          ></textarea>
          <Dropdown
            options={descOptions}
            onChange={(option) => {
              formik.setFieldValue('desc', option.value);
              formik.setFieldTouched('desc', true); // Add this line
            }}
            value={clientsOptions.find(item => item.value === formik.values.desc)}
            arrowOpen={<ArrowIcon />}
            arrowClosed={<ArrowIcon />}
            placeholder="Что описывает вас наиболее корректно?*"
            className={`${errors.desc && touched.desc ? styles.hasError : ''}`}
          />
          <textarea
            name="howToUse"
            placeholder="Как вы хотите использовать наш продукт?"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} // Add this line
            value={formik.values.howToUse}
            className={`${styles.formControl} ${errors.howToUse && touched.howToUse ? styles.hasError : ''}`}
          ></textarea>
          <Dropdown
            options={clientsOptions}
            onChange={(option) => {
              formik.setFieldValue('clients', option.value);
              formik.setFieldTouched('clients', true); // Add this line
            }}
            value={clientsOptions.find(item => item.value === formik.values.clients)}
            arrowOpen={<ArrowIcon />}
            arrowClosed={<ArrowIcon />}
            placeholder="Сколько клиентов вы обрабатываете в месяц?"
            className={`${errors.clients && touched.clients ? styles.hasError : ''}`}
          />
          <div className={styles.buttons}>
            <Button type="submit" onClick={() => store.setContactFormIsOpen(true)} disabled={!formik.isValid}>получить демо <PlusIcon /></Button>
            <AudioPlayer audioSrc={pathsMap.record} />
          </div>
          <div className={styles.hint}>Нажимая на кнопку «Отправить», я соглашаюсь с обработкой персональных данных</div>
        </form>
      </div>
    </div>
  )

  const Success = (
    <div className={styles.success}>
      <SuccessIcon className={styles.successIcon} />
      <div className={styles.heading}>
        Ваша заявка успешно отправлена
      </div>
      <div>
        <p>
          Пожалуйста, будьте на связи. В скором времени мы с вами свяжемся.
        </p>
      </div>
    </div>
  )

  return <Modal handleClose={() => store.setContactFormIsOpen(false)}>
    <div className={styles.container}>
      <div className={styles.close} onClick={() => store.setContactFormIsOpen(false)}>
        <CloseIcon />
      </div>
      {success ? Success : Form}
    </div>
  </Modal>;
})
